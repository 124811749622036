<template>
  <TfCard>
    <template v-slot:title-text>
      <span><b>Confirm booking</b></span>
    </template>
    <template v-slot:title-icon>
      <!-- <img class="pointer" src="@/assets/info.svg" alt="help" /> -->
    </template>
    <template v-slot:content>
      <ConfirmBookingForm />
    </template>
    <template v-slot:footer>
      <book-a-hotel-footer link="guests" />
    </template>
  </TfCard>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmBookingForm from './ConfirmBookingForm.vue';
import BookAHotelFooter from '../BookAHotelFooter.vue';

export default {
  components: {
    ConfirmBookingForm,
    BookAHotelFooter,
  },
  created() {
    this.resetGuest();
    this.resetBookingSteps();
    this.resetSelectedUserType();
  },
  methods: {
    ...mapActions(['resetGuest', 'resetBookingSteps', 'resetSelectedUserType']),
  },
};
</script>

<style>
</style>
