<template>
  <v-flex class="confirm-booking-form-wrapper">
    <v-flex class="booking-details px-5">
      <v-flex class="detail py-4">
        <span>Check in date</span>
        <span>{{ booking.date }}</span>
      </v-flex>
      <v-flex class="detail py-4">
        <span>Check-in time</span>
        <span>{{ booking.timeSlot.checkin }}</span>
      </v-flex>
      <v-flex class="detail py-4">
        <span>Check-out time</span>
        <span>{{ booking.timeSlot.checkout }}</span>
      </v-flex>
      <v-flex class="detail py-4">
        <span>Stay Duration</span>
        <span>{{ booking.durationInHours }}</span>
      </v-flex>
    </v-flex>
    <v-flex class="room-details pa-5">
      <v-flex class="room-label">Your Room</v-flex>
      <v-flex class="room mt-5">
        <div class="name px-3 mt-2 pb-3">{{ booking.selectedRoom.name }}</div>
        <div class="name px-3 mt-2 pb-3">{{ booking.name }}</div>
      </v-flex>
    </v-flex>
    <v-flex v-if="showFreeCancelDateTime" class="pa-5 room-details">
      <v-flex class="room-label">
        Free cancellation until {{ getFreeCancelDateTime(booking.date, booking.timeSlot.checkin).time}} on {{ getFreeCancelDateTime(booking.date, booking.timeSlot.checkin).date}}
      </v-flex>
    </v-flex>
    <v-flex class="pa-5">
      <CouponCode :hotelId="booking?.selectedRoom?.hotel"
                  :roomId="booking?.selectedRoom?._id"
                  :duration="booking.duration"
                  :checkinDate="booking.date"
                  :checkinTime="booking.time"
                  @onDiscountValueChanged="onDiscountValueChanged" />
    </v-flex>
    <v-flex class="payment-details pa-5">
      <v-flex class="payment-label">Total</v-flex>
      <v-flex class="payment py-3">
        <span>{{ booking.duration }} stay</span>
        <span>{{getCurrencyData.shortName}}{{ Math.ceil(getRate() * getCurrencyData.exchangeRate) }}</span>
      </v-flex>
      <v-flex v-if="discount > 0"
              class="payment py-3">
        <span>Discount</span>
        <span>- {{getCurrencyData.shortName}}{{ Math.ceil(discount * getCurrencyData.exchangeRate) }}</span>
      </v-flex>
      <v-flex class="payment py-3">
        <span>Total</span>
        <span class="total">{{getCurrencyData.shortName}}{{ Math.ceil(bookingAmount * getCurrencyData.exchangeRate) }} </span>
      </v-flex>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { round } from 'lodash';
import VueCookies from 'vue-cookies';
import Vue from 'vue';

Vue.use(VueCookies);

export default {
  components: {
    CouponCode: () => import('./Coupon.vue'),
  },
  mounted() {
    const data = localStorage.getItem('bookingroomDetails');
    this.setSelectedRoom(JSON.parse(data));
    this.setCurrencyData(Vue.$cookies.get('getCurrencyData'));
    this.setTotalAmount(Vue.$cookies.get('bookingAmount'));
  },
  computed: {
    ...mapGetters({
      booking: 'booking',
      bookingAmount: 'bookingAmount',
      getCurrencyData: 'getCurrencyData',
    }),
    checkOutTime() {
      const { time, duration } = this.booking;
      // eslint-disable-next-line no-nested-ternary
      const durationNumber = duration === '3h' ? 3 : (duration === '6h' ? 6 : 12);
      const checkOutTime = moment(time, 'hh:mm A').add(durationNumber, 'hours').format('HH:mm');
      return checkOutTime;
    },
    showFreeCancelDateTime() {
      return moment(`${this.booking.date} ${this.booking.time}`, 'ddd DD MMM YY HH').subtract(24, 'hours').subtract(1, 'minutes').isAfter(moment());
    },
  },
  data() {
    return {
      // Use if for future transaction fee
      bookingFee: 0,
      discount: 0,
    };
  },
  created() {
    this.calculateTotalAmount();
  },
  methods: {
    ...mapActions({
      setTotalAmount: 'setTotalAmount',
      setSelectedRoom: 'setSelectedRoom',
    }),
    ...mapMutations(['setSelectedRoom', 'setCurrencyData']),
    calculateTotalAmount() {
      const {
        duration,
        selectedRoom: {
          threeHourRate,
          sixHourRate,
          twelveHourRate,
        },
      } = this.booking;
      let total = round(0 - this.discount || 0, 2);
      switch (duration) {
        case '3h':
          total = round(total + threeHourRate, 2);
          this.setTotalAmount({ total });
          break;
        case '6h':
          total = round(total + sixHourRate, 2);
          this.setTotalAmount({ total });
          break;
        case '12h':
          total = round(total + twelveHourRate, 2);
          this.setTotalAmount({ total });
          break;
        default:
          this.setTotalAmount({ total });
          break;
      }
    },
    getRate() {
      const {
        duration,
        selectedRoom: {
          threeHourRate,
          sixHourRate,
          twelveHourRate,
        },
      } = this.booking;

      switch (duration) {
        case '3h':
          return threeHourRate;
        case '6h':
          return sixHourRate;
        case '12h':
          return twelveHourRate;
        default:
          return 0;
      }
    },
    onDiscountValueChanged(discount) {
      this.discount = discount;
      this.calculateTotalAmount();
    },
    getFreeCancelDateTime(date, time) {
      const datetime = moment(`${date} ${time}`, 'ddd DD MMM YY HH:mm').subtract(24, 'hours').subtract(1, 'minutes');
      return {
        date: datetime.format('DD MMM YYYY'),
        time: datetime.format('hh:mm A'),
      };
    },
  },
};
</script>

<style lang="scss">
.total {
  font-size: 20px;
  font-weight: bold;
}

.confirm-booking-form-wrapper {
  .booking-details {
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #DCDDE6;

    .detail {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DCDDE6;

      &:last-child {
        border: 0;
      }
    }
  }

  .room-details {
    border-bottom: 1px solid #DCDDE6;

    .room-label {
      font-size: 18px;
      line-height: 24px;
    }

    .room {
      border: 2px solid #DCDEE6;
      min-height: 64px;
      border-radius: 4px;
    }
  }

  .payment-details {
    background: #F7F7FC;

    .payment-label {
      font-size: 18px;
      line-height: 24px;
    }

    .payment {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DCDDE6;

      &:last-child {
        border: 0;
      }
    }
  }
}
</style>
